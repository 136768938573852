import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import icon from "../images/favicon.png"

const Container = styled.div`
  margin: 0 auto;
  padding: 32px 0;
  max-width: 800px;
  .to-frontpage {
    font-weight: 400;
    text-decoration: underline;
    font-size: 1rem;
  }
`

const LoadContainer = styled.div`
  height: 300px;
  min-height: 100vh;
`

const Title = styled.h2`
  font-size: 3rem;
`

const Text = styled.p`
  font-size: 1.5rem;
`

const fadeIn = keyframes`
0% {
  transform: rotate(0deg);
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.1);
}

50% {
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.9);
}

100% {
  transform: rotate(360deg);
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.1);
}
`

const LoadingImg = styled.img`
  display: block;
  height: 100px;
  width: 100px;
  margin-left: -50px;
  margin-top: -50px;
  border: 6px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: ${fadeIn} 2s linear infinite;
`

const NotFoundPage = () => {
  const [isLoading, setLoading] = useState(true)
  const currentPage =
    typeof window !== "undefined" ? window.location.pathname : ""

  useEffect(() => {
    if (currentPage) {
      const category = currentPage.split("/")
      const redirectUrl = category?.slice(0, -1)?.join("/")
      if (redirectUrl.match(/dame|herre/)) {
        navigate(redirectUrl)
      } else {
        setLoading(false)
      }
    }
  }, [])

  if (isLoading) {
    return (
      <Layout>
        <LoadContainer>
          <LoadingImg src={icon} alt="Loading" />
        </LoadContainer>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <Title>ØV!</Title>
        <Text>Siden blev ikke fundet</Text>
        <Link className="to-frontpage" to="/">
          Gå tilbage til forsiden
        </Link>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
